<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.34253 0.5C4.65568 0.5 0.842529 4.31315 0.842529 9C0.842529 13.6869 4.65568 17.5 9.34253 17.5C14.0294 17.5 17.8425 13.6869 17.8425 9C17.8425 4.31315 14.0294 0.5 9.34253 0.5ZM13.7662 6.15127L8.2739 12.6897C8.21365 12.7615 8.13869 12.8195 8.05408 12.8597C7.96947 12.9 7.8772 12.9216 7.78351 12.9231H7.77248C7.68084 12.923 7.59022 12.9037 7.50651 12.8664C7.42281 12.8291 7.34788 12.7746 7.28659 12.7065L4.93275 10.0911C4.87297 10.0277 4.82646 9.95299 4.79597 9.87136C4.76548 9.78972 4.75161 9.70282 4.75519 9.61575C4.75876 9.52868 4.7797 9.44321 4.81678 9.36435C4.85386 9.28549 4.90633 9.21484 4.97111 9.15654C5.03588 9.09825 5.11165 9.0535 5.19397 9.02491C5.27629 8.99632 5.36349 8.98447 5.45045 8.99007C5.53742 8.99566 5.62238 9.01858 5.70036 9.05748C5.77834 9.09638 5.84775 9.15048 5.90452 9.21659L7.75532 11.2729L12.765 5.31026C12.8774 5.18035 13.0364 5.09986 13.2076 5.08622C13.3788 5.07258 13.5486 5.12686 13.6801 5.23735C13.8116 5.34783 13.8944 5.50564 13.9105 5.67666C13.9266 5.84767 13.8748 6.01816 13.7662 6.15127Z"
      :fill="setColor()"
    />
  </svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>
