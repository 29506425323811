var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"item"},[_vm._t("icon"),_c('div',[_c('h6',[_vm._v(" "+_vm._s(_vm.title)+" "),(
            _vm.param === 'login_background_image' ||
            _vm.param === 'panel_background_image' ||
            _vm.param === 'panel_background_image_mobile' ||
            _vm.param === 'logomarca' ||
            _vm.param === 'logo'
          )?_c('span',{staticStyle:{"cursor":"pointer","margin-left":"10px"},attrs:{"title":""},on:{"click":_vm.remove}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M18 6L6 18","stroke":"#B5B9C5","stroke-width":"1","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6 6L18 18","stroke":"#B5B9C5","stroke-width":"1","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Remover Imagem ")]):_vm._e()]),_c('span',[_vm._v(_vm._s(_vm.descricao))]),_vm._t("sub")],2),_c('b-form-group',{attrs:{"label":"","label-for":_vm.param}},[(_vm.value !== null)?_c('b-form-checkbox',{attrs:{"name":_vm.param,"size":"lg","switch":""},on:{"change":_vm.update},model:{value:(_vm.contentValue),callback:function ($$v) {_vm.contentValue=$$v},expression:"contentValue"}}):_vm._e()],1)],2),_c('div',{staticClass:"line"})])
}
var staticRenderFns = []

export { render, staticRenderFns }