<template>
  <b-modal id="modal-alert-subdomain" hide-footer size="md">
    <section class="subdomain__content">
      <img src="@/assets/icons/delete-site.svg" alt="" />
      <h4 class="text-danger my-3">Realmente deseja alterar o subdomínio?</h4>
      <ul>
        <li
          class="subdomain__content__consequences"
          v-for="(consequence, index) in consequences"
          :key="index"
          v-html="consequence"
        ></li>
      </ul>
      <section class="subdomain__content__old_new">
        <span>
          <strong>Subdomínio atual:</strong> {{ subdomains.current }}</span
        >
        <span> <strong>Novo subdomínio:</strong> {{ subdomains.new }}</span>
      </section>

      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-checkbox
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          class="mx-3 mt-4"
          :state="status"
        >
          <span>
            <strong>Li e concordo</strong> com as consequências que a alteração
            de subdomínio irá causar!
          </span>
          <b-form-invalid-feedback :state="status">Campo Obrigatório!</b-form-invalid-feedback>
        </b-form-checkbox>
      </form>
      <section class="subdomain__content__buttons mt-4 w-100 text-right">
        <b-button class="decline" variant="transparent" @click="decline">
          Cancelar
        </b-button>
        <b-button class="accept" variant="danger" @click="accept">
          Sim, alterar subdomínio!
        </b-button>
      </section>
    </section>
  </b-modal>
</template>

<script>
export default {
  props: {
    subdomains: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    status: null,
    consequences: [
      "Os clientes que receberam o antigo acesso, <strong>não</strong> receberão novamente o com o novo subdomínio!",
      "Pessoas ao acessarem o antigo subdomínio <strong>não</strong> verão mais suas personalizações no login!",
      "Outras pessoas podem utilizar o antigo subdomínio!",
    ],
  }),
  methods: {
    accept() {
      if (!this.status) {
        this.status = false;
        return;
      }
      this.$emit("accept", this.subdomains.new);
      this.close();
    },
    decline() {
      this.$emit("decline", this.subdomains.current);
      this.close();
    },
    close() {
      this.$root.$emit("bv::hide::modal", "modal-alert-subdomain", "#btnShow");
    },
  },
};
</script>

<style lang="scss" scoped>
.subdomain__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__consequences {
    text-align: left;
    padding: 5px 0;
    list-style-type: "-";
    color: var(--fontcolor);
  }

  &__old_new {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    color: var(--fontcolor);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;

    & .decline {
      color: var(--fontcolor);
    }
  }
}
</style>
