<template>
    <svg width="21" height="21" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M240,88.23a54.43,54.43,0,0,1-16,37L189.25,160a54.27,54.27,0,0,1-38.63,16h-.05A54.63,54.63,0,0,1,96,119.84a8,8,0,0,1,16,.45A38.62,38.62,0,0,0,150.58,160h0a38.39,38.39,0,0,0,27.31-11.31l34.75-34.75a38.63,38.63,0,0,0-54.63-54.63l-11,11A8,8,0,0,1,135.7,59l11-11A54.65,54.65,0,0,1,224,48,54.86,54.86,0,0,1,240,88.23ZM109,185.66l-11,11A38.41,38.41,0,0,1,70.6,208h0a38.63,38.63,0,0,1-27.29-65.94L78,107.31A38.63,38.63,0,0,1,144,135.71a8,8,0,0,0,16,.45A54.86,54.86,0,0,0,144,96a54.65,54.65,0,0,0-77.27,0L32,130.75A54.62,54.62,0,0,0,70.56,224h0a54.28,54.28,0,0,0,38.64-16l11-11A8,8,0,0,0,109,185.66Z" :fill="setColor()"></path>
    </svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>